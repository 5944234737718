<template>
  <v-card
    title="Shopping list app"
    subtitle="Add items to shopping list"
  >
    <v-form @submit.prevent="onSubmit" class="p-2">
      <v-text-field v-model.lazy.trim="form.item" label="Item"></v-text-field>
      <v-btn type="submit" block :loading="loading" :disabled="disableSubmit"
        >Add item</v-btn
      >
    </v-form>
  </v-card>
</template>

<script>
import { createItem } from "@/firebase";
import { reactive, ref } from "vue";
import { computed } from "vue";

export default {
  setup() {
    const form = reactive({ item: "" });
    const loading = ref(false);

    const disableSubmit = computed(() => {
      return loading.value || !form.item.trim();
    });

    const onSubmit = async () => {
      const { item } = form;
      if (!item.trim()) return;

      loading.value = true;

      try {
        await Promise.all([createItem({ item })]);
        form.item = "";
      } catch (error) {
        console.log(error);
      }

      loading.value = false;
    };

    return { form, loading, onSubmit, disableSubmit };
  },
};
</script>

<style></style>
