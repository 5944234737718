<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6 mt-4">
        <ItemCreate />
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-6 mt-4">
        <ItemList />
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-6 mt-4 mb-4">
        <QueuedList />
      </div>
    </div>
  </div>
</template>

<script>
import ItemCreate from "@/components/ShoppingList/ItemCreate.vue";
import QueuedList from "@/components/ShoppingList/QueuedList.vue";
import ItemList from "@/components/ShoppingList/ItemList.vue";

export default {
  name: "ShoppingView",
  components: { ItemCreate, QueuedList, ItemList },
  created() {
    document.title = "Shopping list";
  },
};
</script>

<style>
body {
  background: #111;
}
</style>
