<template>
  <v-card title="Shopping list" subtitle="This is the items in your shopping list">
    <v-table>
      <tbody>
        <tr v-for="{ id, item } in sortedItems" :key="id">
          <td>{{ item }}</td>
          <td class="text-end">
            <v-btn
              prepend-icon="mdi-plus"
              class="mr-2"
              color="secondary"
              size="small"
              @click="addToQueuedList(id, item)"
              >Added</v-btn
            >
            <v-btn
              prepend-icon="mdi-delete"
              variant="outlined"
              color="error"
              size="small"
              @click="deleteItem(id)"
              >Delete</v-btn
            >
          </td>
        </tr>
      </tbody>
    </v-table>
  </v-card>
</template>

<script>
import { useLoadItem, deleteItem, createQueued } from "@/firebase";
import { reactive, computed } from "vue";
import "@mdi/font/css/materialdesignicons.css";

export default {
  setup() {
    const items = useLoadItem();

    const sortedItems = computed(() => {
      const { value } = items;
      return value.slice().sort((a, b) => a.createdAt - b.createdAt);
    });

    const addToQueuedList = async (id, item) => {
      deleteItem(id);
      await Promise.all([createQueued({ item })]);
    };

    return { sortedItems, deleteItem, addToQueuedList };
  },
};
</script>

<style></style>
