<template>
  <div>
    <v-card
      title="Cart"
      subtitle="This is the current items in your shopping cart"
    >
      <v-table>
        <tbody>
          <tr v-for="{ id, item } in sortedItems" :key="id">
            <td>{{ item }}</td>
          </tr>
        </tbody>
      </v-table>
    </v-card>
    <v-btn
      prepend-icon="mdi-delete"
      variant="outlined"
      color="error"
      class="mt-4"
      block
      @click="deleteItems(items)"
      >Clear entire cart</v-btn
    >
  </div>
</template>

<script>
import { deleteQueued, useLoadQueued } from "@/firebase";
import { computed } from "vue";

export default {
  setup() {
    const items = useLoadQueued();

    const sortedItems = computed(() => {
      const { value } = items;
      return value.slice().sort((a, b) => a.createdAt - b.createdAt);
    });

    const deleteItems = async (items) => {
      const deletePromises = items.map((item) => deleteQueued(item.id));
      await Promise.all(deletePromises);
    };

    return { items, sortedItems, deleteItems };
  },
};
</script>

<style></style>
