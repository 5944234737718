import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Nav = _resolveComponent("Nav")!
  const _component_Hero = _resolveComponent("Hero")!
  const _component_About = _resolveComponent("About")!
  const _component_Skills = _resolveComponent("Skills")!
  const _component_Timeline = _resolveComponent("Timeline")!
  const _component_Portfolio = _resolveComponent("Portfolio")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Nav),
    _createVNode(_component_Hero),
    _createVNode(_component_About),
    _createVNode(_component_Skills),
    _createVNode(_component_Timeline),
    _createVNode(_component_Portfolio),
    _createVNode(_component_Footer)
  ]))
}