import firebase from "firebase/compat/app";
import { ref, onUnmounted } from "vue";
import "firebase/compat/firestore";

const config = {
  apiKey: process.env.APIKEY,
  authDomain: "portfolio-c5353.firebaseapp.com",
  projectId: "portfolio-c5353",
  storageBucket: "portfolio-c5353.appspot.com",
  messagingSenderId: "406162521709",
  appId: "1:406162521709:web:3eabc2d578e5601b330964",
  measurementId: "G-4WN3D33EMB",
};

const firebaseApp = firebase.initializeApp(config);

const db = firebaseApp.firestore();
const itemCollection = db.collection("items");
const queuedCollection = db.collection("queued-items");

export const createItem = async (item) => {
  const timestamp = new Date().getTime(); // Get the current time in milliseconds
  const id = timestamp.toString(); // Convert the timestamp to a string

  const docRef = itemCollection.doc(id); // Use the custom ID when creating the document
  await docRef.set(item);

  return docRef.id;
};

export const getItem = async (id) => {
  const item = await itemCollection.doc(id).get();
  return item.exists ? item.data() : null;
};

export const updateItem = (id, item) => {
  return itemCollection.doc(id).update(item);
};

export const deleteItem = (id) => {
  return itemCollection.doc(id).delete();
};

export const useLoadItem = () => {
  const item = ref([]);
  itemCollection.onSnapshot((snapshot) => {
    item.value = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  });
  onUnmounted(close);
  return item;
};

export const createQueued = async (item) => {
  const timestamp = new Date().getTime(); // Get the current time in milliseconds
  const id = timestamp.toString(); // Convert the timestamp to a string

  const docRef = queuedCollection.doc(id); // Use the custom ID when creating the document
  await docRef.set(item);

  return docRef.id;
};

export const getQueued = async (id) => {
  const item = await queuedCollection.doc(id).get();
  return item.exists ? item.data() : null;
};

export const updateQueued = (id, item) => {
  return queuedCollection.doc(id).update(item);
};

export const deleteQueued = (id) => {
  return queuedCollection.doc(id).delete();
};

export const useLoadQueued = () => {
  const item = ref([]);
  queuedCollection.onSnapshot((snapshot) => {
    item.value = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  });
  onUnmounted(close);
  return item;
};
