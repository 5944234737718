
import { Options, Vue } from "vue-class-component";
import Nav from "@/components/Nav.vue";
import Hero from "@/components/Hero.vue";
import About from "@/components/About.vue";
import Skills from "@/components/Skills.vue";
import Portfolio from "@/components/Portfolio.vue";
import Timeline from "@/components/Timeline.vue";
import Footer from "@/components/Footer.vue";

@Options({
  components: {
    Nav,
    Hero,
    About,
    Skills,
    Portfolio,
    Timeline,
    Footer,
  },
})
export default class HomeView extends Vue {}
